<template>
  <div style="position: relative; width: 100%; height: 100%">
    <div v-if="!mode" class="intro-container">
      <p class="intro-prompt">What would you like to do today?</p>
      <div class="card-container">
        <div class="card" @click="setMode('question')">
          <img src="https://flashy-packages-pictures20220201104541386600000001.s3.eu-central-1.amazonaws.com/assets/bot-chat.png" alt="Flashy AI assistant" style="width: 67px !important" />
          <p class="card-header">AI Assistant</p>
          <p class="card-text">Ask questions about rates, invoices, or services. Enjoy a conversational experience with memory capabilities.</p>
        </div>
        <div class="card" @click="setMode('quote')">
          <img src="https://flashy-packages-pictures20220201104541386600000001.s3.eu-central-1.amazonaws.com/assets/bot-quote.png" alt="Flashy AI quote generator" />
          <p class="card-header">Generate Quote</p>
          <p class="card-text">Generate optimized quotes quickly. No memory or conversational capabilities, but highly efficient for quote creation.</p>
        </div>
      </div>
    </div>
    <div v-else style="position: relative; width: 100%; height: 100%">
      <div class="container" v-if="mode !== 'quote'">
        <div class="side-panel">
          <q-input v-model="generalQuestion" label="Ask a general question" type="textarea" filled style="margin-bottom: 12px" :disable="!selectedConversationId" />
          <q-btn class="conversation-btn" color="primary" icon="add" label="Conversation" @click="openNewConversationDialog" :loading="isCreatingConversation" />
          <div class="conversations-container">
            <div v-for="conversation in conversations" :key="conversation.id" class="conversation" @click="selectConversation(conversation.id)">
              <q-icon name="chat" size="14px" />
              <p class="truncate-text">{{ conversation.title }}</p>
            </div>
          </div>

          <div class="btns-container">
            <q-btn class="back-btn" color="#f1f1f1" icon="arrow_back" label="Go back" @click="goBack" :loading="isCreatingConversation" rounded />
            <q-btn class="send-btn" color="primary" icon="send" label="Send" @click="handleSend" :loading="isProcessing" rounded />
          </div>
        </div>
        <q-dialog v-model="isNewConversationDialogOpen" persistent>
          <q-card style="min-width: 350px">
            <q-card-section>
              <div class="text-h6">New Conversation</div>
            </q-card-section>

            <q-card-section class="q-pt-none">
              <q-input outlined v-model="newConversationTitle" autofocus @keyup.enter="createNewConversation" label="Conversation Title" color="black" />
            </q-card-section>

            <q-card-actions align="right" class="text-primary">
              <q-btn flat label="Cancel" v-close-popup color="black" />
              <q-btn flat label="Create" @click="createNewConversation" :loading="isCreatingConversation" color="black" />
            </q-card-actions>
          </q-card>
        </q-dialog>
        <div class="main-panel">
          <div v-if="selectedConversationId === null" class="assistant-intro">Create a new conversation or select an existing one to start chatting with the AI assistant.</div>
          <div v-else-if="isConversationLoading">Loading conversation...</div>
          <div v-else-if="isConversationError">Error loading conversation: {{ conversationError.message }}</div>
          <div v-else-if="!conversation || conversation.length === 0">No messages in this conversation.</div>
          <div v-else class="chat-container">
            <q-chat-message
              v-for="message in conversation"
              :key="message.id"
              :name="message.sender === 'human' ? 'You' : 'Assistant'"
              :sent="message.sender === 'human'"
              :bg-color="message.sender === 'human' ? 'primary' : 'white'"
              text-color="black"
              :stamp="formatTheDate(message.created_at)"
              :avatar="
                message.sender === 'human'
                  ? 'https://flashy-assets20211018140904806600000006.s3.eu-central-1.amazonaws.com/email/ask.png'
                  : 'https://flashy-assets20211018140904806600000006.s3.eu-central-1.amazonaws.com/email/ai-assistant.png'
              "
            >
              <template v-slot:default>
                <div v-if="message.sender === 'human'">{{ message.content }}</div>
                <div v-else v-html="renderMarkdown(message.content)" class="markdown-content"></div>
              </template>
            </q-chat-message>
          </div>
        </div>
        <div class="references-panel" v-if="formattedMetadata.length > 0">
          <div v-for="item in formattedMetadata" :key="item.id" class="reference-item" @click="openDialog(item.fullText)">
            <p class="item-company">{{ item.company }}</p>
            <p class="item-invoice-number">{{ item.invoiceNumber }}</p>
            <p class="item-invoice-date">{{ item.invoiceDate }}</p>
            <p class="item-services">{{ item.services }}</p>
          </div>
        </div>
        <q-dialog v-model="isDialogVisible">
          <q-card style="min-width: 350px; max-width: 600px; width: 90vw">
            <q-card-section>
              <div class="text-h6">Invoice Details</div>
            </q-card-section>

            <q-card-section class="q-pt-none">
              <pre class="invoice-text">{{ selectedFullText }}</pre>
            </q-card-section>

            <q-card-actions align="right">
              <q-btn flat label="Close" color="primary" v-close-popup />
            </q-card-actions>
          </q-card>
        </q-dialog>
      </div>
      <div class="container" v-else>
        <div class="side-panel">
          <q-input v-model="newService.description" label="Description" type="textarea" filled style="margin-bottom: 12px" />
          <div class="form-footer">
            <q-input v-model.number="newService.quantity" label="Quantity" type="number" filled class="quantity-input" />
            <q-btn label="Add" color="primary" class="add-service-btn" flat icon="add" @click="addService" />
          </div>
          <div class="services-container">
            <div class="service" v-for="(service, index) in selectedServices" :key="index">
              <p class="service-description">{{ service.description }}</p>
              <div style="display: flex">
                <p class="service-quantity" caption>Quantity: {{ service.quantity }}</p>
                <q-btn flat round color="negative" icon="delete" @click="deleteService(index)" class="delete-btn" />
              </div>
            </div>
          </div>

          <div class="btns-container">
            <q-btn class="back-btn" color="#f1f1f1" icon="arrow_back" label="Go back" @click="goBack" :loading="isCreatingConversation" rounded />
            <q-btn class="send-btn" color="primary" icon="send" label="Send" @click="handleSend" :loading="isProcessing" rounded />
          </div>
        </div>
        <div class="main-panel">
          <div class="main-content" v-html="renderedContent"></div>
        </div>
        <div class="references-panel" v-if="formattedMetadata.length > 0">
          <div v-for="item in formattedMetadata" :key="item.id" class="reference-item" @click="openDialog(item.fullText)">
            <p class="item-company">{{ item.company }}</p>
            <p class="item-invoice-number">{{ item.invoiceNumber }}</p>
            <p class="item-invoice-date">{{ item.invoiceDate }}</p>
            <p class="item-services">{{ item.services }}</p>
          </div>
        </div>
        <q-dialog v-model="isDialogVisible">
          <q-card style="min-width: 350px; max-width: 600px; width: 90vw">
            <q-card-section>
              <div class="text-h6">Invoice Details</div>
            </q-card-section>

            <q-card-section class="q-pt-none">
              <pre class="invoice-text">{{ selectedFullText }}</pre>
            </q-card-section>

            <q-card-actions align="right">
              <q-btn flat label="Close" color="primary" v-close-popup />
            </q-card-actions>
          </q-card>
        </q-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api';
import { QuotesApi, ConversationsApi } from '@api/index';
import { marked } from 'marked';
import { useQuery, useMutation } from '@tanstack/vue-query';

marked.use({ gfm: true, breaks: true });

export default {
  name: 'Quotes',
  setup() {
    const mode = ref(null);
    const selectedServices = ref([]);
    const newService = ref({
      description: '',
      quantity: 1
    });
    const generalQuestion = ref('');
    const isProcessing = ref(false);
    const content = ref('');
    const metadata = ref([]);
    const isDialogVisible = ref(false);
    const selectedFullText = ref('');

    const isNewConversationDialogOpen = ref(false);
    const newConversationTitle = ref('');
    const isCreatingConversation = ref(false);
    const selectedConversationId = ref(null);

    const renderMarkdown = content => {
      if (!content) return '';
      return marked(content);
    };

    const goBack = () => {
      // Reset mode
      mode.value = null;

      // Reset selected services
      selectedServices.value = [];

      // Reset new service
      newService.value = {
        description: '',
        quantity: 1
      };

      // Reset general question
      generalQuestion.value = '';

      // Reset processing state
      isProcessing.value = false;

      // Reset content
      content.value = '';

      // Reset metadata
      metadata.value = [];

      // Reset dialog visibility
      isDialogVisible.value = false;

      // Reset selected full text
      selectedFullText.value = '';

      // Reset new conversation dialog state
      isNewConversationDialogOpen.value = false;
      newConversationTitle.value = '';
      isCreatingConversation.value = false;

      // Reset selected conversation ID
      selectedConversationId.value = null;
    };

    const {
      data: conversationsData,
      isLoading,
      isError,
      error,
      refetch
    } = useQuery({
      queryKey: ['conversations'],
      queryFn: ConversationsApi.getAllConversations,
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 60 * 24 // 24 hours
    });

    const conversations = computed(() => conversationsData.value.data.conversations || []);

    const createConversationMutation = useMutation({
      mutationFn: metadata => ConversationsApi.createConversation(metadata),
      onSuccess: () => {
        refetch();
      }
    });

    const openNewConversationDialog = () => {
      isNewConversationDialogOpen.value = true;
      newConversationTitle.value = '';
    };

    const createNewConversation = async () => {
      if (!newConversationTitle.value.trim()) {
        // You might want to show an error message here
        return;
      }

      isCreatingConversation.value = true;
      try {
        const newConversation = await createConversationMutation.mutateAsync({
          title: newConversationTitle.value.trim()
        });
        isNewConversationDialogOpen.value = false;
        newConversationTitle.value = '';
        generalQuestion.value = '';
      } catch (error) {
        console.error('Error creating new conversation:', error);
        // You might want to show an error message to the user here
      } finally {
        isCreatingConversation.value = false;
      }
    };

    const {
      data: conversationData,
      isLoading: isConversationLoading,
      isError: isConversationError,
      error: conversationError
    } = useQuery({
      queryKey: ['conversation', selectedConversationId],
      queryFn: () => ConversationsApi.getConversationHistory(selectedConversationId.value),
      enabled: computed(() => !!selectedConversationId.value)
    });

    const conversation = computed(() => conversationData.value.data.messages || []);

    const formatTheDate = dateString => {
      return new Date(dateString).toLocaleString();
    };

    const selectConversation = id => {
      selectedConversationId.value = id;
    };

    const setMode = newMode => {
      mode.value = newMode;
    };

    const openDialog = fullText => {
      selectedFullText.value = fullText;
      isDialogVisible.value = true;
    };

    const addService = () => {
      if (newService.value.description.trim() !== '') {
        selectedServices.value.push({ ...newService.value });
        newService.value = { description: '', quantity: 1 };
      }
    };

    const deleteService = index => {
      selectedServices.value.splice(index, 1);
    };

    const renderedContent = computed(() => {
      return marked(content.value);
    });

    const formattedMetadata = computed(() => {
      if (!metadata.value || !Array.isArray(metadata.value)) {
        return [];
      }
      return metadata.value.map((item, index) => {
        return {
          id: index + 1,
          company: item.company || 'N/A',
          invoiceNumber: item.invoice_number || 'N/A',
          invoiceDate: item.invoice_date || 'N/A',
          services: Array.isArray(item.services) ? item.services.join(', ') : 'N/A',
          totalAmount: item.total_amount || 'N/A',
          fullText: item.full_text || 'N/A'
        };
      });
    });

    const handleQuoteGeneration = async () => {
      if (selectedServices.value.length === 0) {
        // Show an error message or alert
        return;
      }
      isProcessing.value = true;
      content.value = '';
      metadata.value = []; // Reset metadata at the start of each generation

      try {
        const response = await QuotesApi.generateQuote(selectedServices.value);
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        const readChunk = async () => {
          const { done, value } = await reader.read();
          if (done) return;

          const chunk = decoder.decode(value, { stream: true });
          const lines = chunk.split('\n').filter(line => line.trim() !== '');

          for (const line of lines) {
            try {
              const parsedData = JSON.parse(line);
              if (parsedData.type === 'metadata') {
                metadata.value = parsedData.data || []; // Ensure it's an array
                console.log('Metadata:', metadata.value);
              } else if (parsedData.type === 'quote') {
                content.value += parsedData.data;
              }
            } catch (error) {
              console.error('Error parsing chunk:', error);
            }
          }

          await readChunk();
        };

        await readChunk();
      } catch (error) {
        console.error('Error generating quote:', error);
        content.value = 'Error generating quote. Please try again.';
      } finally {
        isProcessing.value = false;
        selectedServices.value = [];
      }
    };

    const handleGeneralQuery = async () => {
      if (generalQuestion.value.trim() === '') {
        // Show an error message or alert
        return;
      }
      isProcessing.value = true;
      content.value = '';
      metadata.value = []; // Reset metadata at the start of each query
      // Add user message to the conversation
      if (conversation.value) {
        conversation.value.push({
          id: Date.now(),
          sender: 'human',
          content: generalQuestion.value,
          created_at: new Date().toISOString()
        });
      }
      try {
        // Query invoices using the optimized query
        const queryResponse = await QuotesApi.queryInvoices(generalQuestion.value, selectedConversationId.value);
        const queryReader = queryResponse.body.getReader();
        const queryDecoder = new TextDecoder('utf-8');
        let streamingMessageId = null;
        let buffer = '';
        const readQueryChunk = async () => {
          const { done, value } = await queryReader.read();
          if (done) return;
          const chunk = queryDecoder.decode(value, { stream: true });
          buffer += chunk;
          while (buffer.indexOf('\n') !== -1) {
            const lineEndIndex = buffer.indexOf('\n');
            const line = buffer.slice(0, lineEndIndex);
            buffer = buffer.slice(lineEndIndex + 1);
            try {
              const parsedData = JSON.parse(line);
              if (parsedData.type === 'answer') {
                const words = parsedData.data.split(' ');
                for (const word of words) {
                  await new Promise(resolve => setTimeout(resolve, 50)); // Add a small delay between words
                  if (!streamingMessageId) {
                    streamingMessageId = 'streaming-' + Date.now();
                    conversation.value.push({
                      id: streamingMessageId,
                      sender: 'assistant',
                      content: word,
                      created_at: new Date().toISOString()
                    });
                  } else {
                    const existingMessage = conversation.value.find(m => m.id === streamingMessageId);
                    if (existingMessage) {
                      existingMessage.content += ' ' + word;
                    }
                  }
                }
              } else if (parsedData.type === 'context') {
                metadata.value = parsedData.data || []; // Update metadata for side panel
                console.log('Received context:', parsedData.data);
              }
            } catch (error) {
              console.error('Error parsing chunk:', error);
            }
          }
          await readQueryChunk();
        };
        await readQueryChunk();
        // After streaming is complete, update the conversation with the final message
        if (conversation.value && streamingMessageId) {
          const streamingMessage = conversation.value.find(m => m.id === streamingMessageId);
          if (streamingMessage) {
            streamingMessage.id = Date.now();
          }
        }
        // Refetch the conversation to ensure it's up to date
        await refetch();
      } catch (error) {
        console.error('Error processing query:', error);
        if (conversation.value) {
          conversation.value.push({
            id: Date.now(),
            sender: 'assistant',
            content: 'Error processing your query. Please try again.',
            created_at: new Date().toISOString()
          });
        }
      } finally {
        isProcessing.value = false;
        generalQuestion.value = ''; // Clear the input after sending
      }
    };

    const handleSend = () => {
      if (mode.value === 'quote') {
        handleQuoteGeneration();
      } else {
        handleGeneralQuery();
      }
    };

    return {
      mode,
      selectedServices,
      newService,
      generalQuestion,
      addService,
      deleteService,
      handleSend,
      isProcessing,
      content,
      renderedContent,
      formattedMetadata,
      metadata,
      isDialogVisible,
      openDialog,
      selectedFullText,
      setMode,
      conversations,
      isLoading,
      isError,
      error,
      refetch,
      conversation,
      isConversationLoading,
      isConversationError,
      conversationError,
      selectConversation,
      selectedConversationId,
      formatTheDate,
      isNewConversationDialogOpen,
      newConversationTitle,
      isCreatingConversation,
      openNewConversationDialog,
      createNewConversation,
      renderMarkdown,
      goBack
    };
  }
};
</script>

<style scoped>
.container {
  width: 100vw;
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 340px 1fr 340px;
  gap: 12px;
  padding: 12px;
}

.side-panel {
  display: flex;
  flex-direction: column;
  background-color: #fefefe;
  padding: 16px;
  overflow-y: auto;
}

.main-panel {
  max-width: 100%;
  width: 100%;
  position: relative;
}

.form-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6px;
}

.quantity-input {
  padding: 0 !important;
}

.add-service-btn {
  color: var(--main-text-color) !important;
  background-color: #fecb2f !important;
  margin-left: 12px;
}

.service {
  display: flex;
  flex-direction: column;
  color: var(--main-text-color) !important;
  background-color: #f1f1f1;
  padding: 12px;
  text-align: left;
  margin-top: 12px;
}

.service-description {
  font-size: 16px;
  padding: 0;
  margin: 0;
}

.service-quantity {
  font-size: 14px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-btn {
  margin-left: auto;
}

.btns-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: absolute;
  bottom: 30px;
  width: 300px;
}

.send-btn {
  color: var(--main-text-color) !important;
  margin-left: 6px;
  height: 45px !important;
}
.back-btn {
  color: var(--main-text-color) !important;
  height: 45px !important;
}

.conversation-btn {
  color: var(--main-text-color) !important;
  margin-bottom: 18px;
}

.main-content {
  padding: 40px;
  padding-top: 12px;
  overflow-y: scroll;
  height: calc(100vh - 150px); /* Adjust as needed */
  text-align: left;
  width: 100%;
}

.chat-container {
  padding: 40px;
  padding-top: 12px;
  overflow-y: scroll;
  height: calc(100vh - 100px); /* Adjust as needed */
  text-align: left;
  width: 100%;
}

.form-label {
  color: var(--main-text-color);
  text-align: left;
}

.references-panel {
  display: flex;
  flex-direction: column;
  background-color: #fefefe;
  padding: 16px;
  overflow-y: auto;
  text-align: left;
}

.reference-item {
  padding: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.invoice-text {
  white-space: pre-line;
  word-wrap: break-word;
  max-height: 70vh;
  overflow-y: auto;
  background-color: #f5f5f5;
  padding: 18px;
  border-radius: 4px;
}

.reference-item:hover {
  background-color: #e0e0e0;
}

.item-company {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.item-invoice-number {
  margin: 0;
  padding: 0;
}

.item-invoice-date {
  margin: 0;
  padding: 0;
  border-bottom: 0.5px solid var(--main-text-color);
  margin-bottom: 12px;
  padding-bottom: 12px;
}

.item-services {
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
}

/* Add some basic styling for the rendered markdown */
::v-deep .main-content {
  * {
    color: var(--main-text-color) !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    font-size: 20px;
    margin-bottom: 12px;
  }

  table {
    border-collapse: collapse;
    margin-bottom: 1em;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }
}

.intro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.card-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.card {
  width: 450px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  text-align: left;
  color: var(--main-text-color) !important;
  padding: 60px;
  color: var(--main-text-color);
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

img {
  width: 60px;
}

.card-header {
  font-size: 20px;
  padding: 0;
  margin: 0;
  font-weight: 600;
}

.card-text {
  padding: 0;
  margin: 0;
}

.intro-prompt {
  color: var(--main-text-color);
  font-size: 24px;
  font-weight: 300 !important;
}

.conversation {
  text-align: left;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 0;
  cursor: pointer;
}

.truncate-text {
  padding: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 265px !important;
  margin-left: 6px;
}

.conversation:hover .truncate-text {
  font-weight: bold;
  font-size: 14px;
  transition: all 0.25s;
}

.conversation-container {
  height: 100%;
  overflow-y: auto;
}

.q-message {
  margin-bottom: 12px;
  text-align: left;
}

.conversations-container,
.services-container {
  height: 50vh;
  overflow-y: scroll;
}

.assistant-intro {
  display: flex;
  flex-direction: column;
}
</style>
